<template>
    <div>
        <a-spin :spinning="loading">
            <div class="bg-w pd20" style="min-height: 800px;min-width: 1000px;">
                <div style="width: 800px;">
                    <div class="ft20 ftw500 cl-main">开放平台设置</div>

                    <a-alert class="mt20" message="1.微信开放平台设置;https://open.weixin.qq.com/" type="info" />

                    <div v-if="datas!=null" class="mt20">
                        <a-form-model ref="ruleForm" :model="datas" :label-col="{ span: 4}" :wrapper-col="{ span: 20}">

                            <div>
                                <a-form-model-item label="开放平台AppId">
                                    <a-input v-model="datas.AppId" style="width: 300px;" />
                                </a-form-model-item>
                                <a-form-model-item label="开放平台AppSecret">
                                    <a-input v-model="datas.AppSecret" style="width: 300px;" />
                                </a-form-model-item>
                                <a-form-model-item label="消息通知TOKEN">
                                    <a-input v-model="datas.TOKEN" style="width: 300px;" />
                                </a-form-model-item>
                                <a-form-model-item label="消息通知秘钥">
                                    <a-input v-model="datas.message_key" style="width: 300px;" />
                                </a-form-model-item>
                                <a-form-model-item label="开发小程序ID">
                                    <a-input v-model="datas.applet_id" style="width: 300px;" />
                                </a-form-model-item>
                                <a-form-model-item label="开发小程序上传秘钥">
                                    <a-textarea :rows="5" v-model="datas.applet_key" style="width: 400px;" />
                                </a-form-model-item>
                            </div>

                            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                                <a-button type="primary" @click="saveData">
                                    保存设置
                                </a-button>
                            </a-form-model-item>
                        </a-form-model>
                    </div>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            datas: null,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this.$http.api('api/Install/InstallInfo', {
                type: 'OpenPlatform'
            }).then(res => {
                this.loading = false;
                let datas = res.data.json_data
                this.datas = {
                    AppId: datas.AppId.value,
                    AppSecret: datas.AppSecret.value,
                    TOKEN: datas.TOKEN.value,
                    message_key: datas.message_key.value,
                    applet_id: datas.applet_id.value,
                    applet_key: datas.applet_key.value,
                };
            }).catch(res => {
                this.loading = false;
            })
        },
        saveData() {
            this.loading = true;
            this.$http.api('api/Install/OpenPlatformEdit', this.datas).then(res => {
                this.loading = false;
                this.$message.success('保存成功');
            }).catch(res => {
                this.loading = false;
            })
        }
    }
}
</script>

<style>
</style>